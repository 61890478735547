<template>
    <section class="relative bg-cover bg-top bg-no-repeat text-white h-full"
        :style="{ backgroundImage: `url('images/${medium}/wave.png')` }">
        <footer class="container relative absolute bottom:0 mx-auto pb-4">
            <div class="footer__container pt-32 md:pt-48 lg:pt-96 justify-items-center">
                <div class='footer__disclaimer'>
                    <div
                        class='wrapper___inner flex flex-col mx-auto max-w-screen-xl px-2 sm:pt-6 lg:px-8 items-center justify-items-center text-center'>
                        <div class='footerlinks mx-auto'>
                            <a href='/'>Home</a>
                            <a target='_blank' href=' https://www.gilead.co.uk/privacy-statements'>Privacy Policy</a>
                            <a href='/terms'>Terms of Use</a>
                            <a target='_blank'
                                href='https://www.gilead.co.uk/privacy-statements/cookie-statement'>Cookie
                                Policy</a>
                        </div>

                        <p class='footer__disclaimer__boxed'>
                            Adverse events should be reported.
                            <br>
                            <br>
                            For the UK, reporting forms and information can be found at
                            <a class="il -mobileicon" href="https://coronavirus-yellowcard.mhra.gov.uk/">
                                https://coronavirus-yellowcard.mhra.gov.uk/</a>
                            or via the Yellow Card app (download from the Apple App Store or Google Play Store).
                            Adverse events should also be reported to Gilead to
                            <a href="mailto:safety_fc@gilead.com">safety_FC@gilead.com</a> or <a
                                href="tel:+441223897500">+44 (0) 1223 897500.</a>
                            <br>
                            <br>
                            For Ireland, reporting forms and information can be found at
                            <a class="il -mobileicon" href="https://www.hpra.ie">https://www.hpra.ie</a>
                            and can be reported to HPRA on <a href="tel:+35316764971">+35316764971.</a><br>
                            Adverse events should also be reported to Gilead to
                            <a href="mailto:safety_fc@gilead.com">safety_FC@gilead.com</a> or
                            <a href="tel:+353214825999.">+353 (0) 21 482 5999.</a>
                        </p>

                        <p class="font-light">
                            This website includes promotional content on the following Gilead products: Veklury®
                            (remdesivir)
                            <br>
                            Prescribing Information:
                            <TooltipComponent>
                                <template #target>
                                    <a class="il -mobileicon -white underline" href="https://www.emcpi.com/pi/38228"
                                        target="_blank" rel="nonreferrer">UK</a>
                                </template>
                                <template #content>{{ TooltipMsg }}</template>
                            </TooltipComponent>
                            &nbsp;|&nbsp;
                            <TooltipComponent>
                                <template #target>
                                    <a v-on:click="OpenURL('https://www.medicines.ie/medicines/veklury-100-mg-powder-for-concentrate-for-solution-for-infusion-34986/spc')"
                                        href="#" class="il -mobileicon -white underline">IE</a>
                                </template>
                                <template #content>{{ TooltipMsg }}</template>
                            </TooltipComponent>
                        </p>

                        <div class="footer__additional_monitoring w-fit p-2 mb-2">
                            <p class="text-black">▼ This medicinal product is subject to additional monitoring.</p>
                        </div>

                        <p class="font-light mb-[10px]">UK-VKY-0408 | January 2025</p>

                    </div>
                </div>

                <div v-if="!isMobile()">
                    <p class='footer__lower'>
                        Gilead Sciences Ltd +44 (0) 8000 113 700; Registered as a limited company in England and Wales
                        [registered number 02543818]; Registered office: 280 High Holborn, London, WC1V 7EE, England
                        &nbsp;|&nbsp;
                        <a href="mailto:ukmed.info@gilead.com" class="underline">UKMed.Info@gilead.com</a>
                    </p>
                </div>
                <div v-else>
                    <p class='footer__lower'>
                        Gilead Sciences Ltd +44 (0) 8000 113 700; Registered as a limited company in England and Wales
                        [registered number 02543818];<br /> Registered office:<br />
                        280 High Holborn, London, WC1V 7EE, England | <a href="mailto:ukmed.info@gilead.com"
                            class="underline">UKMed.Info@gilead.com</a>
                    </p>
                </div>

                <div class='footer__lower__banner'>
                    <img src="@/assets/images/logo-gilead-white.svg" class='footer__lower__logo inline-block'>
                    <span class="footer__copy">© 2025 Gilead Sciences, Inc. All rights reserved. Gilead
                        and the Gilead logo are trademarks of Gilead Sciences, Inc.</span>
                </div>
            </div>
        </footer>
    </section>
</template>

<script>
import TooltipComponent from './tooltip.vue';

export default {
    name: 'FooterComponent',
    props: {
        TooltipMsg: String,
        OpenURL: Function
    },
    computed: {
        medium() {
            if (this.isMobile()) {
                return "mobile"
            } else {
                return "desktop"
            }
        }
    },
    methods: {
        isMobile() {
            if (screen.width <= 760) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    components: {
        TooltipComponent,
    },
};
</script>
