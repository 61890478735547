<template>
  <div class="banner">
    <div class="banner">
      <span class="piwrap">
        Prescribing information: Veklury<sup>®</sup>▼ (remdesivir)
        <TooltipComponent>
          <template #target>
            <a href="https://www.emcpi.com/pi/38228" target="_blank" rel="nonreferrer"
              class="il -mobileicon text-black underline">UK</a>
          </template>
          <template #content>{{ TooltipMsg }}</template>
        </TooltipComponent>
        &nbsp;|&nbsp;
        <TooltipComponent>
          <template #target>
            <a v-on:click="OpenURL('https://www.medicines.ie/medicines/veklury-100-mg-powder-for-concentrate-for-solution-for-infusion-34986/spc')"
              href="#" class="il -mobileicon text-black underline">IE</a>
          </template>
          <template #content>{{ TooltipMsg }}</template>
        </TooltipComponent>
      </span>
      <br />
      <span class="piwrap">
        This promotional website is fully funded and organised by Gilead Sciences Ltd. Information on this page is
        for UK and Irish Healthcare Professionals.
      </span>
    </div>
  </div>
</template>

<script>
import TooltipComponent from "@/components/tooltip.vue";

export default {
  name: 'BannerComponent',
  props: {
    TooltipMsg: String,
    OpenURL: Function,
  },
  components: {
    TooltipComponent
  }
};
</script>

<style scoped>
.container {
  max-width: 1440px;
}

.container p {
  color: #25282A;
}
</style>